//Styles of overview-slider
.slider {

	&__control {

		display: flex;
		justify-content: center;

		&.no-bullets__medium {

			@include breakpoint(medium only) {

				display: none;
			}
		}

		&.no-bullets__large {

			@include breakpoint(large) {

				display: none;
			}
		}
	}

	&__bullet {

		height: 8px;

		display: none;
		background-color: $grey-5;
		outline: none;
		cursor: pointer;

		// Active class of slider bullet by glideJS
		&.glide__bullet--active {

			background-color: $grey-20;
		}

		// First Slider-Bullet and 3n+1 (On medium are three Boxes visible -> Only the first and every other third Bullet is visible)
		@include breakpoint(medium) {

			&:first-child,
			&:nth-child(3n+1) {

				width: 128px;
				margin-right: 4px;
				display: block;
			}
		}

		// First Slider-Bullet and 4n+1 (On xlarge are four Boxes visible -> Only the first and every other fourth Bullet is visible)
		@include breakpoint(xlarge) {

			&:nth-child(3n+1) {
				display: none;
			}

			&:first-child,
			&:nth-child(4n+1) {

				width: 200px;
				margin-right: 4px;
				display: block;
			}
		}
	}

	// Slider depends on amount of content-elements inside and the current Breakpoint (Go to: Partial Overview.Slider.html)
	&__show {

		display: none;

		&.slider-small {

			@include breakpoint(small) {

				display: block;
			}

			@include breakpoint(smedium) {

				display: none;
			}
		}

		&.slider-smedium {

			@include breakpoint(smedium) {

				display: block;
			}

			@include breakpoint(medium) {

				display: none;
			}
		}

		&.slider-medium {

			@include breakpoint(medium) {

				display: block;
			}

			@include breakpoint(xlarge) {

				display: none;
			}
		}

		&.slider-xlarge {

			@include breakpoint(xlarge) {

				display: block;
			}
		}
	}
}
