//Styles for ww-overview Element intro-stage
.intro-stage {
	position: relative;
	width: 100%;
	height: auto;
	text-align: center;

	&__text {
		font-size: $font-size-xs;
		line-height: $line-height-s;

		@include breakpoint(medium) {
			font-size: $font-size-s;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-m;
			line-height: $line-height-m;
		}

		p {
			font-size: $font-size-xs;
			line-height: $line-height-s;

			@include breakpoint(medium) {
				font-size: $font-size-s;
			}

			@include breakpoint(xlarge) {
				font-size: $font-size-m;
				line-height: $line-height-m;
			}
		}
	}

	& .headline {

		padding-bottom: rem-calc(8);

		@include breakpoint(medium) {

			padding-bottom: rem-calc(16);
		}

		@include breakpoint(xlarge) {

			padding-bottom: rem-calc(24);
		}
	}

	//No Intro Image is selected
	&__no-image {

		display: none;
	}

	//Intro Image is selected
	&__with-image {

		&--image {
			position: relative;
			z-index: 1;
			height: 200px;
			background-size: cover;
			background-position: center center;

			@include breakpoint(medium) {

				height: 304px;
			}

			@include breakpoint(xlarge) {

				height: 500px;
			}
		}
	}

	//No Intro Image is selected
	.padding-to-header {

		padding: 32px 0;

		@include breakpoint(medium) {

			padding: 56px 0 80px;
		}

		@include breakpoint(xlarge) {

			padding: 80px 0;
		}
	}

	//Intro Image is selected
	.padding-to-image {

		padding: 16px 0 32px;

		@include breakpoint(medium) {

			padding: 40px 0 44px;
		}

		@include breakpoint(xlarge) {

			padding: 64px 0 80px;
		}
	}
}
