// Styles for Page-intro Mask Element
.page-intro {
	position: relative;
	width: 100%;
	height: auto;

	&__image-wrapper {
		position: relative;
		z-index: 1;
		height: 312px;
		background-size: cover; // background-image inline pageintro
		background-position: center center; // background-image inline pageintro

		@include breakpoint(medium) {
			height: 400px;
		}

		@include breakpoint(xlarge) {
			height: 576px;
		}
	}

	&__text {
		position: absolute;
		top: 50%;
		width: 100%;
		z-index: 2;
		transform: translateY(-50%);
		text-align: center;
	}

	&__headline {
		padding-bottom: 16px;

		@include breakpoint(medium) {
			padding-bottom: 24px;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		cursor: pointer;

		&--link {
			padding-right: 1rem;
			color: $primary-color;
			font-size: $font-size-xs;
			line-height: $line-height-s;
			font-weight: $global-weight-bold;
			transition: color .3s ease-in-out;
		}

		svg {

			path {

				transition: fill .3s ease-in-out;
			}
		}

		&:hover,
		&:active,
		&:focus {

			background-color: $primary-color;
			color: $white;

			span {

				color: $white;
			}

			svg {

				path {

					fill: $white;
				}
			}
		}
	}
}
