//Styles for quotation mask-element
.quotation {
	margin-bottom: 56px;
	padding: 32px 0;
	background-color: $grey-extra;
	text-align: center;

	@include breakpoint(medium) {
		margin-bottom: 80px;
		padding: 64px 0;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 120px;
		padding: 80px 0;
	}

	&__headline {

		padding-bottom: 8px;

		@include breakpoint(medium) {
			padding-bottom: 36px;
		}

		@include breakpoint(xlarge) {
			padding-bottom: 16px;
		}
	}

	&__text {
		padding-bottom: 24px;

		display: block;
		font-size: $font-size-xs;
		line-height: $line-height-s;

		@include breakpoint(medium) {
			padding-bottom: 60px;

			font-size: $font-size-s;
			line-height: $line-height-s;
		}

		@include breakpoint(xlarge) {
			padding-bottom: 64px;

			font-size: $font-size-m;
			line-height: $line-height-m;
		}
	}

	&__writer {
		position: relative;
		padding-top: 6px;

		font-size: $font-size-xxs;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;


		@include breakpoint(xlarge) {

			padding-top: 16px;

			font-size: $font-size-xs;
			line-height: $line-height-s;
		}

		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			width: 24px;
			height: 2px;

			background-color: $primary-color;
		}

		&--name {
			height: 24px;
		}
	}
}
