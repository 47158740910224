// Page Header
.page-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;

	width: 100%;
	height: rem-calc(48);

	border-bottom: 2px solid $primary-color;

	background-color: $white;
	font-family: $body-font-family;

	user-select: none;
	transition: height .2s ease-in-out;

	@include breakpoint(medium) {

		height: rem-calc(96);
	}

	@include breakpoint(xlarge) {

		height: rem-calc(111);
		border-bottom: 4px solid $primary-color;
	}

	&.is-open {

		border-bottom: 2px solid $white;

		@include breakpoint(xlarge) {

			border-bottom: 4px solid $white;
		}
	}

	// If Menu or Login is open the Height of the Nav should always be like it is-top
	&.is-top,
	&.is-scrolled {
		&.is-open {
			height: rem-calc(48);

			@include breakpoint(medium) {
				height: rem-calc(96);
			}

			@include breakpoint(xlarge) {
				height: rem-calc(111);
			}
		}
	}

	&__inner {
		height: 100%;
		background-color: $white;

		&--container {
			height: 100%;
		}

		&--grid {
			height: 100%;
			flex-direction: row;
			flex-wrap: nowrap;
			color: $black;
		}

	}

	// Hover effects on Menu and Login
	&__menu,
	&__login {

		cursor: pointer;

		&__inner {

			display: flex;
		}

		&:hover {

			.page-header__text--menu,
			.page-header__text--login {

				color: $primary-color;
			}

			& .icon-menu {

				&__nav,
				&__login {

					&:before,
					&:after {

						background: $primary-color;
					}

					&.is-open {
						background-color: transparent;
					}
				}

				&__nav {

					width: 1rem;
					background: $primary-color;

					@include breakpoint(medium) {

						width: 1.5rem;
					}
				}
			}

			svg {
				path {
					fill: $primary-color;
				}
			}
		}
	}

	//Page Header Logo
	&__logo {
		position: absolute;
		top: .5rem;
		left: 50%;
		z-index: 3;
		transform: translateX(-50%);
		display: block;

		&--svg {

			width: rem-calc(98);
			height: rem-calc(56);
			transition: width .2s ease-in-out, height .2s ease-in-out;

			// Breakpoints Logo
			@include breakpoint(medium) {

				width: rem-calc(210);
				height: rem-calc(120);
			}
			@include breakpoint(xlarge) {

				width: rem-calc(238);
				height: rem-calc(136);
			}
		}
	}

	//Page Header Login
	&__login {
		align-items: center;
		display: flex;
		float: right;
		cursor: pointer;

		svg {

			width: rem-calc(16);
			height: rem-calc(16);
			transform: translateY(2px);
			transition: opacity .2s ease-in-out;

			@include breakpoint(medium) {

				width: rem-calc(24);
				height: rem-calc(24);
			}

			path {

				transition: fill .2s ease-in-out;
			}

			&.page-header__logout--svg {

				transform: translateY(2px);

				@include breakpoint(medium) {

					transform: translateY(4px);
				}
			}
		}

		&.is-open {

			svg {

				opacity: 0;
			}
		}

		&--icon-wrapper {
			position: relative;
		}

		&--icon-small {
			@include breakpoint(medium) {
				display: none;
			}
		}

		&--icon-medium-up {
			display: none;
			@include breakpoint(medium) {
				display: inline-block;
			}
		}
	}

	&__text {
		font-size: $font-size-xs;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;
		color: $grey-80;

		@include breakpoint(medium) {
			font-size: $font-size-s;
		}

		@include breakpoint(xlarge) {
			line-height: $line-height-m;
			font-size: $font-size-m;
		}

		&--menu {

			padding-left: 8px;
			transition: color .2s ease-in-out;

			@include breakpoint(medium) {
				padding-left: 16px;
			}
		}

		&--login {

			padding-right: 8px;
			transition: color .2s ease-in-out;

			@include breakpoint(medium) {
				padding-right: 16px;
			}

			&--large {

				display: none;

				@include breakpoint(large) {
					display: inline-block;
				}
			}
		}
	}

	//if page header is-scrolled header height and logo size are decreasing
	&.is-scrolled {
		position: fixed;
		height: rem-calc(48);

		@include breakpoint(medium) {

			height: rem-calc(65);
		}
		@include breakpoint(xlarge) {

			height: rem-calc(80);
		}

		& .page-header {

			&__logo {

				&--svg {
					// Breakpoints Logo
					@include breakpoint(medium) {

						width: rem-calc(140);
						height: rem-calc(80);
					}
					@include breakpoint(xlarge) {

						width: rem-calc(168);
						height: rem-calc(96);
					}
				}
			}
		}

		//if page header is-scrolled and menu or login are open the logo-size don't change
		&.is-open {

			& .page-header {

				&__logo {

					&--svg {
						// Breakpoints Logo
						@include breakpoint(medium) {

							width: rem-calc(210);
							height: rem-calc(120);
						}
						@include breakpoint(xlarge) {

							width: rem-calc(238);
							height: rem-calc(136);
						}
					}
				}
			}
		}
	}
}
