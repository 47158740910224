//Basic Styles
html {

	overflow-x: hidden;
	overflow-y: scroll;

	color: $grey-80;
	font-weight: $global-weight-normal;

	//&.hide-overflow {
	//
	//	overflow: hidden;
	//}
}

body {

	overflow-x: hidden;
	padding: rem-calc(48) 0 0;

	@include breakpoint(medium) {

		padding: rem-calc(96) 0 0;
	}

	@include breakpoint(xlarge) {

		padding: rem-calc(111) 0 0;
	}
}

a {

	display: inline-block;
	color: $grey-80;
	transition: color .2s ease-in-out;

	&:hover {

		color: $primary-color;
		text-decoration: none;
	}
}

b,
strong {

	font-weight: $global-weight-bold;
}

// Style Selected Text
::selection {

	background: $primary-color;
	color: $white;
}

p {

	margin-bottom: 1rem;

	color: $grey-80;
	font-weight: 300;

	&:last-child {

		margin-bottom: 0;
	}
}

li {

	color: $grey-80;
}

//image
.image {

	padding-bottom: 16px;

	@include breakpoint(xlarge) {

		padding-bottom: 32px;
	}
}

//overlay for images
.overlay {

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $la-overlay;
		z-index: 2;
	}
}

em {
	font-weight: inherit;
	font-style: italic;
}

sup {
	vertical-align: super;
	top: 1px;
}

sub {
	vertical-align: bottom;
	bottom: .33rem;
}
