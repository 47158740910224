.language-switcher {
  &__container {
    position: relative;

    &:hover {

      cursor: pointer;
    }
  }

  &__list {
    position: absolute;
    top: -1.25rem;

    padding: .313rem .5rem;
    max-height: 2rem;

    overflow: hidden;
    background: $white;
    transition: max-height .3s linear;

    @include breakpoint(medium) {

      padding: 0 .5rem;
      max-height: 2.5rem;
    }

    &.is-open {

      max-height: 5rem;
    }

    &__item {

      margin-bottom: .5rem;

      &:last-child {

        margin: 0;
      }

      @include breakpoint(medium) {

        margin: 0;
      }

      img {

        width: 22px;

        border-radius: 50%;
      }
    }
  }

  &__text {

    display: none;
    margin: 0 0 0 .5rem;

    font-size: $font-size-s;
    line-height: $line-height-m;

    vertical-align: middle;
    color: $grey-30;

    @include breakpoint(medium) {

      display: inline-block;

      font-size: $font-size-m;
      line-height: $line-height-l;
    }

    &.is-active {

      color: $black;
    }
  }

  &__arrow {
    position: absolute;
    top: -.5rem;
    left: 2.25rem;

    transform: rotate(180deg);

    @include breakpoint(medium) {
      top: -.4rem;
      left: 5rem;
    }

    @include breakpoint(xlarge) {
      left: 8.5rem;
    }

    &.is-open {
      top: -.3rem;

      transform: rotate(0deg);

      @include breakpoint(medium) {
        top: -.2rem;
      }
    }
  }
}
