//General Styles for Content-Elements specially Margin and Headlines
.content-element {
	margin-bottom: 3.5rem;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(84px);
	}

	@include breakpoint(xlarge) {
		margin-bottom: rem-calc(120px);
	}

	&__header {
		margin-bottom: 2rem;

		@include breakpoint(medium) {
			margin-bottom: 2.5rem;
		}

		@include breakpoint(xlarge) {
			margin-bottom: 4rem;
		}

		&__title {
			font-size: $font-size-s;
			line-height: $line-height-s;
			font-weight: bold;
			text-align: center;
			color: $grey-80;

			@include breakpoint(medium) {
				font-size: $font-size-m;
				line-height: $line-height-m;
			}

			@include breakpoint(xlarge) {
				font-size: $font-size-l;
				line-height: $line-height-l;
			}
		}
	}

	&--no-margin {
		margin-bottom: 0;
	}
}
