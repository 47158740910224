// Local Variables
$nav-height-small: 2.75rem;
$nav-height-medium: 5.75rem;

//Styles for main-navigation
.nav-main {
	position: absolute;
	z-index: -1;
	top: 100%;
	transform: translateY(-100%);
	width: 100%;
	height: calc(100vh - #{$nav-height-small});
	padding-top: rem-calc(40);
	background-color: $white;

	overflow: hidden;
	transition: transform .3s ease-out;

	// start of desktop nav
	@include breakpoint(medium) {
		height: calc(100vh - #{$nav-height-medium});
		padding-top: rem-calc(80);
	}

	@include breakpoint(xlarge) {
		height: auto;
		padding-top: rem-calc(92);

		.is-scrolled & {

			height: auto;
		}
	}

	// states
	&.is-open {
		overflow-y: auto;
		transform: translateY(0);
		border-bottom: 2px solid $primary-color;

		@include breakpoint(xlarge) {
			border-bottom: 4px solid $primary-color;
		}
	}

	&__inner {

		position: relative;

		@include breakpoint(xlarge) {

			margin-bottom: 128px;
		}
	}

	&__list {

		background-color: $white;

		&--element {

			text-decoration: none;

			&.is-triggered {

				@include breakpoint(medium) {
					color: $primary-color;
				}
			}
		}
	}

	// Back to Main Nav Button is only visible on Small
	&__back {

		display: inline-block;
	}

	&__link {

		display: flex;
		align-items: center;
		cursor: pointer;
		text-decoration: none;
		//transition: color .2s ease-in-out;

		&:hover {
			text-decoration: underline;
			color: $primary-color;

			svg {

				fill: $primary-color;
				transition: fill .3s ease-in-out;

				rect {

					stroke: $white;
					transition: stroke .3s ease-in-out;
				}

				path {

					fill: $white;
					transition: stroke .3s ease-in-out;
				}
			}
		}

		//Transitions on svg
		svg {

			fill: $white;
			transition: fill .2s ease-in-out;

			rect {

				stroke: $primary-color;
				transition: stroke .2s ease-in-out;
			}

			path {

				fill: $primary-color;
				transition: fill .2s ease-in-out;
			}
		}

		.is-triggered & {
			@include breakpoint(medium) {

				svg {

					fill: $primary-color;

					rect {

						stroke: $primary-color;
					}

					path {

						fill: $white;
					}
				}
			}
		}

		&--first {
			display: flex;
			justify-content: space-between;
			max-width: 20rem;
			font-size: $font-size-xs;
			line-height: $font-size-s;
			font-weight: bold;
			margin-bottom: 1.5rem;

			.nav-main__back & {
				margin-bottom: 2rem;
			}

			@include breakpoint(medium) {
				max-width: none;
				margin-bottom: 40px;
				font-size: $font-size-s;
				line-height: $font-size-s;
			}

			@include breakpoint(xlarge) {

				margin-bottom: 44px;

				font-size: $font-size-m;
				line-height: $font-size-m;
			}
		}

		&--second {
			position: relative;
			padding-bottom: 16px;

			display: flex;
			justify-content: space-between;

			font-size: $font-size-xxs;
			line-height: $font-size-s;
			text-decoration: none;

			@include breakpoint(medium) {

				padding-bottom: 8px;

				font-size: $font-size-xs;
				line-height: $font-size-s;
			}

			@include breakpoint(xlarge) {

				margin-bottom: 32px;
				padding-bottom: 0;

				justify-content: start;

				font-size: $font-size-s;
				line-height: $font-size-s;
			}

			&:hover {

				color: $primary-color;
				text-decoration: underline;

				a {

					text-decoration: underline;
				}

				svg {

					fill: $primary-color;

					path {

						fill: $primary-color;
					}
				}
			}
		}

		&--active {

			color: $primary-color;
		}

		//No Access Link Element
		&--no-access {

			padding-right: 24px;
			cursor: pointer;

			@include breakpoint(smedium) {

				padding-right: 0;
			}

			@include breakpoint(xlarge) {

				.multiple-columns & {
					margin-right: 48px;
					padding-right: 32px;
				}
			}

			@include breakpoint(xxlarge) {

				.multiple-columns & {
					margin-right: 96px;
				}
			}
		}
	}

	&__icon {
		&--no-access {

			@include breakpoint(xlarge) {
				position: absolute;
				right: 0;

				.multiple-columns & {
					right: 48px;
				}
			}

			@include breakpoint(xxlarge) {

				.multiple-columns & {
					right: 96px;
				}
			}

			svg {
				fill: none;
				transition: fill .3s ease-in-out;

				path {

					fill: $grey-50;
					transition: fill .3s ease-in-out;
				}
			}
		}
	}
}

// DESKTOP NAV
@include breakpoint(medium) {
	.nav-main {

		&__inner {

			display: flex;
			flex-wrap: wrap;
		}

		&__list {

			flex: 0 0 33.33%; // using 33.33% cause Grid is not possible

			// MODIFIER
			&--first {

				border-right: 0;
			}

			// MODIFIER
			&--secondary {

				display: none;

				&.is-open {

					display: block;
					border-left: 2px solid $grey-10;
					margin-left: 56px;
					padding-left: 56px;
					flex-grow: .25; // using .25 cause Grid is not possible

					@include breakpoint(xlarge) {

						margin-left: 84px;
						padding-left: 84px;

						&.multiple-columns {

							columns: 2;
							flex-grow: .75; // using .75 cause Grid is not possible 3/4
						}
					}
				}
			}
		}
	}
}

@include breakpoint(xlarge) {
	.nav-main {
		&__list {
			flex: 0 0 25%;
		}
	}
}

// MOBILE NAV
@include breakpoint(smedium down) {

	.nav-main {

		&__list {
			width: 100%;
			position: absolute;
			transition: left .3s ease-in-out;
			height: 100%;

			// MODIFIER
			&--first {

				left: 0;

				// states
				&.is-closed {

					left: -100vw;
				}
			}

			// MODIFIER
			&--secondary {

				width: 50%;
				left: 100vw;

				@include breakpoint(small down) {

					width: 100%;
				}

				// states
				&.is-open {

					left: 0;
				}

				&.is-closed {

					left: 100vw;
				}
			}
		}
	}
}
