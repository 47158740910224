//Styles for page-footer include footer__top, footer__bottom & footer__navigation
.page-footer {

	&__icon {
		width: 3rem;
		height: 3rem;

		@include breakpoint(xlarge) {
			width: 4rem;
			height: 4rem;
		}
	}

	//Start footer__top
	&__top {

		&--inner {
			width: 100%;

			background-color: $primary-color;
			color: $white;
		}

		&--container {

			padding: 12px 0;

			@include breakpoint(medium) {

				padding: 0;
			}
		}

		&--cell {

				padding: 12px 0;

				@include breakpoint(medium) {

					padding: 24px 0;
				}

				@include breakpoint(xlarge) {

					padding: 32px 0;
				}
			}

		&--text {

			font-size: $font-size-xxs;
			line-height: $line-height-s;
			font-weight: $global-weight-bold;

			@include breakpoint(xlarge) {

				font-size: $font-size-s;
				line-height: $line-height-s;
			}
		}
	}

	//Start footer__bottom
	.page-footer__bottom {
		&--inner {

			background-color: $white;
		}
	}
}

//Start footer__navigation
.footer__navigation {

	padding: 16px 0 12px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	color: $grey-80;
	font-size: $font-size-xxs;

	@include breakpoint(medium) {

		padding: 16px 0;

		display: block;
		text-align: center;
		align-items: center;
		margin: 0 auto;
	}

	@include breakpoint(xlarge) {

		padding: 24px 0;
	}

	//Different Order between small and medium/large
	:first-child {
		order: 2;

		@include breakpoint(medium) {

			order: -1;
		}
	}

	&--company {
		padding-right: 40px;
		color: $grey-80;
	}

	&--link {
		margin-bottom: .5rem;
		padding-right: 40px;
		color: $grey-80;
		line-height: 1.5rem;

		@include breakpoint(medium) {
			margin-bottom: 0;
		}

		&:hover {

			text-decoration: underline;
			cursor: pointer;
		}
	}
}
