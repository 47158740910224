/* Cookiebar Wrapper General */
.cookiebar {
	background: $la-overlay-black;
	color: $white;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	padding: 20px;
	max-height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;

	@include breakpoint(medium) {
		width: 60vw;
	}

	@include breakpoint(xlarge) {
		width: 40vw;
	}

	&.is-closed {

		display: none;
	}

  &.js-deactivated {

    display: none;
  }

	&__title {
		font-size: $font-size-s;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;
		margin-bottom: 10px;
		color: $white;
		text-transform: none;

		@include breakpoint(medium) {
			font-size: $font-size-m;
			line-height: $line-height-m;
		}
	}

	&__bodytext {
		font-size: $font-size-xxs;
		line-height: $line-height-s;
		color: $white;
		margin-bottom: 16px;

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
			margin-bottom: 24px;
		}

		@include breakpoint(xlarge) {
			margin-bottom: 32px;
		}
	}

	&__button {
		font-size: $font-size-xs;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;
		cursor: pointer;
		display: inline-block;
		width: auto;
		padding: 8px 16px;
		color: $white;
		background: $primary-color;
		border: 2px solid $primary-color;
		border-radius: 4px;
		text-decoration: none;
		text-transform: none;
		text-align: left;
		margin-bottom: 8px;

		@include breakpoint(medium) {
			font-size: $font-size-s;
			line-height: $line-height-s;
			padding: 12px 24px;
			margin-bottom: 16px;
		}

		&:hover,
		&:focus,
		&:active {
			color: $primary-color;
			background: $white;
		}

		&__button-wrap {
			margin-bottom: 20px;
		}
	}

	&__link {
		cursor: pointer;
		font-size: $font-size-xxs;
		line-height: $line-height-s;
		color: $white;
		text-decoration: underline;
		text-transform: none;
		background: none;
		padding: 0;
		border: 0;
		display: inline-block;
		width: auto;
		text-align: left;
		margin: 0;

		&:hover,
		&:focus,
		&:active {
			color: $white;
			background: none;
			border: 0;
		}

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
		}
	}

	&__checkbox {
		position: absolute;
		opacity: 0;
		left: -9999rem;

		& + label {
			display: block;
			margin: 0;
			position: relative;
			cursor: pointer;
			padding-left: 32px;
			font-size: $font-size-xxs;
			line-height: $line-height-s;
			color: $white;

			@include breakpoint(medium) {
				font-size: $font-size-s;
				line-height: $line-height-s;
			}

			&:before {
				content: '\2714';
				position: absolute;
				left: 0;
				top: 0;
				display: inline-block;
				width: 24px;
				height: 24px;
				background: transparent;
				border: 2px solid $grey-20;
				padding-left: 4px;
				color: transparent;
				font-weight: $global-weight-bold;


				@include breakpoint(medium) {

					padding-left: 2px;
				}
			}
		}

		&:checked {
			& + label {
				&:before {
					color: $primary-color;
					font-weight: $global-weight-bold;
				}
			}
		}

		&--disabled + label {
			opacity: .5;

			&:before {
				background: transparent;
			}
		}

		&-container {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 16px;

			@include breakpoint(medium) {
				margin-bottom: 24px;
			}

			@include breakpoint(xlarge) {
				margin-bottom: 32px;
			}
		}

		&-wrap {
			flex-basis: 100%;
			flex-grow: 0;
			margin-bottom: 16px;

			@media only screen and (min-width: 768px) {
				flex-basis: 50%;
			}
		}
	}

	& input {
		&[type='checkbox'] {
			& + label {
				margin: 0;
			}
		}
	}

	&__accordeon {

		&__trigger {
			display: flex;
			align-items: center;

			&__icon {
				line-height: 0;
			}

			&__text {
				font-size: $font-size-xxs;
				line-height: $line-height-s;

				@include breakpoint(medium) {
					font-size: $font-size-xs;
					line-height: $line-height-s;
				}
			}
		}

		&__content {
			display: none;
		}

		&.is-open &__content {
			display: block;
		}

		&.is-open &__trigger {
			margin-bottom: 20px;
		}

		&.is-open &__trigger__icon {
			transform: rotate(180deg);
		}
	}

	&__history__item {
		display: flex;
		flex-flow: row wrap;

		& .date {
			flex-basis: 100%;
			flex-grow: 0;
			margin-bottom: 10px;
		}

		& .category,
		& .choice {
			flex-basis: 50%;
			flex-grow: 0;
			word-break: break-word;
		}
	}

	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 2;
		cursor: pointer;
		line-height: 0;
	}
}

