.overlay-js-hint {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: $la-overlay-black-js;

  z-index: 999;

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: $white;
    border-top: 4px solid $la-orange;

    @include breakpoint(medium) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 600px;
      height: 232px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding: 40px;
  }


  &__svg {
    margin: 0 auto;
    text-align: center;
  }


  &__headline {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: bold;
    text-align: left;
    margin: 24px 0 8px;
  }

  &__text {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: 300;
    text-align: left;
    margin-bottom: 40px;
  }

  &__link {

    color: $la-orange;

    &:hover {
      text-decoration: underline;
    }
  }
}
