//Styles for mask element accordion
.accordion {
	@extend .content-element;

	.frame-type-uploads & {
		margin-bottom: 0;
	}

	&__headline {
		text-align: center;
		padding-bottom: 24px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
	}

	&__element {
		width: 100%;
		padding: 12px 0;
		border-bottom: 2px solid $grey-10;

		@include breakpoint(medium) {
			padding: 16px 0;
		}

		@include breakpoint(xlarge) {
			padding: 24px 0;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		&--subheadline {
			font-size: 16px;
			line-height: 24px;
			font-weight: $global-weight-bold;
			user-select: none;

			&:hover {
				color: $primary-color;
			}

			@include breakpoint(xlarge) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		&--svg {
			transition: transform .3s ease-in-out;

			@include breakpoint(xlarge) {
				width: 32px;
				height: 32px;
			}
		}

		&.is-active {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	&__bottom {
		overflow: hidden;
		transition: height .4s ease-in-out;
	}

	&__upload,
	&__textmedia {
		display: flex;
		margin: 8px 0;

		@include breakpoint(medium) {
			margin: 16px 0;
		}

		@include breakpoint(xlarge) {
			margin: 20px 0;
		}
	}

	&__textmedia {

		width: 100%; //Fix for correct Sizes of Videos inside Text and Media
	}
}
