//Styles for mask element timeline
.timeline {
	font-family: $body-font-family;
	color: $grey-80;

	@include breakpoint(medium) {
		padding-bottom: 80px;
	}

	@include breakpoint(medium) {
		padding-bottom: 120px;
	}

	//Content-Element including Date and Story
	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;

		padding: 2rem 0;

		@include breakpoint(medium) {
			padding: 0;

			//odd elements  (Date left / Story right)
			&:nth-child(odd) {
				flex-direction: row;

				.timeline__story {

					padding: 64px 0 64px 94px;
				}
			}

			//even elements (Story left / Date right)
			&:nth-child(even) {

				flex-direction: row-reverse;

				.timeline__story {

					padding: 64px 94px 64px 0;
				}
			}

			&:after {
				position: absolute;
				left: 50%;

				content: '';
				height: 100%;
				width: 4px;
				margin-left: -2px;

				background-color: $primary-color;
			}
		}

		@include breakpoint(xlarge) {

			&:nth-child(odd) {
				.timeline__story {

					padding: 80px 0 112px 150px;
				}
			}

			&:nth-child(even) {
				.timeline__story {

					padding: 80px 150px 112px 0;
				}
			}
		}
	}

	//Wrapper of the date including only the Year
	&__date {

		display: flex;
		flex: 0 0 50%;
		justify-content: flex-start;

		padding-bottom: 16px;

		font-size: $font-size-xl;
		line-height: $font-size-xl; // indiviual case font-size = line-height
		font-weight: $global-weight-bold;
		color: $primary-color;

		@include breakpoint(medium) {

			width: 50%;
			justify-content: center;
			align-items: center;

			padding: 0;

			font-size: $font-size-xxl;
			line-height: $font-size-xxl; // indiviual case font-size = line-height
		}

		@include breakpoint(xlarge) {

			font-size: rem-calc(120);
			line-height: rem-calc(120); // indiviual case font-size = line-height
		}
	}

	//Wrapper of the Story including image, headline and text
	&__story {
		flex: 0 0 50%;

		@include breakpoint(medium) {

			width: 50%;
		}

		&--image {
			position: relative;
			width: 100%;
			height: auto;
			margin-bottom: 16px;

			@include breakpoint(xlarge) {

				margin-bottom: 24px;
			}
		}

		&--headline {

				font-weight: $global-weight-bold;
				font-size: $font-size-xs;
				line-height: $line-height-s;

				@include breakpoint(medium) {
					font-size: $font-size-s;
					line-height: $line-height-s;
				}

				@include breakpoint(xlarge) {
					font-size: $font-size-m;
					line-height: $line-height-m;
				}
		}

		&--text {

			display: block;
			font-size: $font-size-xxs;
			line-height: $line-height-s;

			@include breakpoint(medium) {
				font-size: $font-size-xs;
				line-height: $line-height-s;
			}

			@include breakpoint(xlarge) {
				font-size: $font-size-s;
				line-height: $line-height-s;
			}
		}
	}

	&__dots {
		display: none;

		@include breakpoint(medium) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%);

			width: 16px;
			height: 16px;

			display: block;
			border-radius: 50%;
			background-color: $primary-color;
		}

		@include breakpoint(xlarge) {

			width: 24px;
			height: 24px;
		}
	}
}
