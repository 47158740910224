// Overview Button is only visible on landing page
.overview-button {

	display: none;
}

.landing-page {

	& .overview-button {

			display: flex;
			justify-content: center;

		@include breakpoint(medium) {

			margin-top: 24px;//Depends on landing-page and not on element above: margin-top instead of margin-bottom
		}

		&__link {

			display: flex;
			align-items: center;

			font-size: $font-size-xs;
			line-height: $font-size-s;
			font-weight: $global-weight-bold;

			@include breakpoint(xlarge) {

				font-size: $font-size-s;
			}

			//Transitions on svg
			svg {
				fill: $white;
				transition: fill .3s ease-in-out;

				rect {

					stroke: $primary-color;
					transition: stroke .3s ease-in-out;
				}

				path {

					fill: $primary-color;
					transition: fill .3s ease-in-out;
				}
			}

			//Hover effects on svg
			&:hover {

				svg {
					fill: $primary-color;
					transition: fill .3s ease-in-out;

					rect {

						stroke: $white;
					}

					path {

						fill: $white;
					}
				}
			}
		}
	}
}
