.overview-list {

	&__wrapper {

		&.center-smedium {

			@include breakpoint(smedium only) {

				justify-content: center;
			}
		}

		&.center-medium-up {

			@include breakpoint(medium) {

				justify-content: center;
			}
		}
	}
}
