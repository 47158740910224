@use 'sass:map';

.form {

  // Captcha
  .captcha {
    display: flex;
    width: 100%;
    margin: 0;

    @include breakpoint(large) {
      width: 50%;
    }

    a {
      margin: auto 0;
    }

    &--wrap {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;

      @include breakpoint(large) {
        flex-direction: row;
      }
    }

    &--image {
      height: 100%;

      &--wrap {
        overflow: hidden;
        width: 100%;
        height: 4rem;
        margin: 0 .5rem .5rem 0;
        background-color: $grey-extra;

        @include breakpoint(large) {
          margin: 0 .5rem 0 0;
        }

        @include breakpoint(xlarge) {
          height: 4.5rem;
        }
      }
    }

    &--reload {
      cursor: pointer;

      &:focus-visible {
        outline: $black;
      }

      .icon {
        path {
          transition: fill 150ms ease-in-out;
          fill: $black;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .icon {
            path {
                fill: $grey-50;
            }
          }
        }
      }
    }

    &--input {
      width: 100%;
      margin-bottom: 0;

      @include  breakpoint(large) {
        width: 50%;
        margin: 0 0 0 1rem;
      }
    }
  }
}
