.headline {

	display: block;
	font-family: $body-font-family;
	font-weight: $global-weight-bold;
	color: $grey-80;


	// 20px / 24px
	&--s {
		font-size: $font-size-s;
		line-height: $line-height-s;

		@include breakpoint(medium) {

			font-size: $font-size-m;
			line-height: $line-height-m;
		}

		@include breakpoint(xlarge) {

			font-size: $font-size-l;
			line-height: $line-height-l;
		}
	}

	// 24px / 32px
	&--m {

		font-size: $font-size-m;
		line-height: $line-height-m;

		@include breakpoint(medium) {
			font-size: $font-size-l;
			line-height: $line-height-l;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-xl;
			line-height: $line-height-xl;
		}
	}

	// 36px / 40px
	&--l {

		font-size: $font-size-l;
		line-height: $line-height-l;
	}

	// 48px / 56px
	&--xl {

		font-size: $font-size-xl;
		line-height: $line-height-xl;
	}

	// 64px / 72px
	&--xxl {

		font-size: $font-size-xxl;
		line-height: $line-height-xxl;
	}

	//Special Headlines

	&--highlight-text {
		font-size: $font-size-m;
		line-height: $line-height-m;

		@include breakpoint(xlarge) {
			font-size: $font-size-l;
			line-height: $line-height-l;
		}
	}

	&--white {

		color: $white;
		font-weight: $global-weight-bold;

		& h2 {

			font-weight: $global-weight-bold;
		}
	}
}
